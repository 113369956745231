<template>
  <div class="vue-form-wizard steps-transparent d-flex justify-content-end">
    <div class="wizard-navigation">
      <ul role="tablist" class="wizard-nav wizard-nav-pills">
        <li :class="{'active': item.active}" step-size="md" v-for="(item, index) in tabs" :key="index">
          <a href="javascript:void(0)">
            <div role="tab" tabindex="0" id="step-AccountDetails0" aria-controls="AccountDetails0" class="wizard-icon-circle md checked">
              <div class="wizard-icon-container bg-primary" v-if="item.active">
                <i class="wizard-icon feather" :class="`icon-${item.icon}`" />
              </div>
              <i class="wizard-icon feather" :class="`icon-${item.icon}`" v-else/>
            </div>
            <div class="d-flex flex-column align-items-start" :class="{'text-muted': !item.active}">
              <span class="stepTitle" :class="{'active': item.active, 'text-primary': item.active}">{{ item.title }}</span>
              <span>{{ item.subtitle }}</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardFormList',
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  }
}
</script>