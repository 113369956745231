<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <b-row>
        <b-col cols="4">
          <h1>Avaliação de crédito</h1>
          <h4>Libere sua oferta inicial para visualizar os valores que podem ser disponibilizados para suas operações de contratação de fretes.</h4>
        </b-col>
        <b-col cols="8">
          <wizard-form-list :tabs="tabs" />
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Escolha abaixo a melhor maneira de realizar sua análise de crédito para ter acesso ao seu limite e taxa." v-if="step === 1">
      <b-row>
        <b-col cols="9">
          <b-card border-variant="light" class="mb-0 h-100">
            <div class="d-flex flex-column mb-1">
              <h2 class="mb-2">Prepare seus arquivos para envio</h2>
              <span>Faça o passo a passo para liberar sua oferta inicial. Além de ter acesso aos valores liberados, você ganhará descontos progressivos em suas taxas, além de também aumentar o seu limite de contratação. </span>
            </div>
            <div class="d-flex flex-column mb-1">
              <h3 class="mb-2">➜   Os arquivos do seu SPED dos últimos 3 anos</h3>
              <span>No passo seguinte você vai precisar enviar os arquivos referentes ao SPED dos três anos anteriores. Assista ao video para entender como baixar seus arquivos. </span>
            </div>
            <div class="d-flex flex-column mb-1">
              <h3 class="mb-2">➜   Notas fiscais de contas a pagar e receber</h3>
              <span>Também será requisitado à você dois arquivos separados em formato XML. Um com os dados de contas a pagar e o outro contendo os dados de contas a receber, os dois contendo informações referentes aos últimos 12 meses. </span>
            </div>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card class="padlocks-bg">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <b-img src="@/assets/images/dashboard/padlocks.svg" width="100" height="100"/>
            </div>
            <b-button variant="primary" @click="checkForSpeds" class="w-100">Continuar</b-button>
          </b-card>
          <b-card class="padlocks-bg">
            <div class="d-flex flex-row justify-content-between">
              <div class="d-flex flex-row justify-content-between">
                <b-img src="@/assets/images/dashboard/whatsup.svg" />
                <div class="d-flex flex-column justify-content-center align-items-center ml-2 mr-2">
                  <h4>Precisa de ajuda?</h4>
                  <span>Chame no WhatsApp</span>
                </div>
              </div>
              <b-button variant="outline-primary"> Chamar</b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <sped-form v-if="step === 2" @spedSubmited="step = 3" :loadedSpeds="loadedSpeds"/>
    <container-form v-if="step === 3">
      <template #form>
        <div>
          <h2>Contas a pagar e a receber últimos 12 meses</h2>
          <span>0,05% de desconto na taxa</span>

          <p class="mt-2">Para finalizar esta etapa, precisamos que você prepare separadamente em dois arquivos em formato XML, o contas a pagar e o contas a receber com período dos últimos 12 meses.</p>

          <div class="d-flex justify-content-end">
            <b-button variant="outline-primary mr-1" @click="showCancelSwal">Cancelar</b-button>
            <b-button variant="primary" @click="checkForNfes">Continuar</b-button>
          </div>
        </div>
      </template>
    </container-form>
    <nfe-form v-if="step === 4" @nfeSubmited="stepThree" :loadedNfes="loadedNfes" />
    <scr-permission v-if="step === 5"/>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BImg, BButton, BLink, BIcon } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import WizardFormList from '@/views/client/dashboard/components/WizardFormList.vue'
import SpedForm from '@/views/client/dashboard/components/manual_offer/SpedForm.vue'
import NfeForm from '@/views/client/dashboard/components/manual_offer/NfeForm.vue'
import ScrPermission from '@/views/client/dashboard/components/manual_offer/ScrPermission.vue'
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'

export default {
  name: 'ManualOffer',
  components: {
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    BLink,
    BIcon,
    WizardFormList,
    SpedForm,
    NfeForm,
    ContainerForm,
    ScrPermission
  },
  data () {
    return {
      loadedSpeds: [],
      loadedNfes: [],
      sped1: '',
      step: 1,
      tabs: [
        {
          title: 'Iniciar liberação',
          subtitle: 'Comece o passo a passo',
          icon: 'unlock',
          active: true
        },
        {
          title: 'Envie os documentos',
          subtitle: 'SPED e Contas',
          icon: 'file',
          active: false
        },
        {
          title: 'Permissão SCR',
          subtitle: 'Autorize a visualização',
          icon: 'search',
          active: false
        }
      ]
    }
  },
  methods: {
    stepTwo () {
    },
    stepThree () {
      this.step = 5
      this.tabs[1].active = false
      this.tabs[2].active = true
    },
    showCancelSwal () {
      this.$swal.fire({
        text: 'Essa etapa é necessária para dar continuidade na contratação. Você tem certeza que deseja cancelar esta operação?',
        reverseButtons: true,
        confirmButtonColor: '#093272',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.dismiss === 'cancel') this.$router.push({ name: 'dashboard' })
      })
    },
    async checkForSpeds () {
      try {
        const { data } = await this.$store.dispatch('dashboard/checkForSpeds')

        this.loadedSpeds = data.data.map(file => {
          return {
            fileName: this.extractNameFromS3Url(file.arquivo.url),
            ano_referencia: file.ano_referencia,
            id: file.id,
          }
        })

        this.step = 2
        this.tabs[0].active = false
        this.tabs[1].active = true
      } catch (error) {
        console.log(error)
      }
    },
    async checkForNfes () {
      try {
        const { data } = await this.$store.dispatch('dashboard/checkForNfes')

        this.loadedNfes = data.map(file => {
          return {
            fileName: this.extractNameFromS3Url(file.arquivo.url),
            tipo: file.tipo,
            id: file.id,
          }
        })

        this.step = 4
      } catch (error) {
        console.log(error)
      }
    },
    extractNameFromS3Url(url) {
      url = url.split('?')
      const name = url[0].split('/')

      return name[name.length - 1]
    }
  },
  computed: {
    activeTab () {
      return this.tabs.find(tab => tab.active)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

.padlocks-bg {
  background-color: #F8FAFC;
}

</style>