<template>
  <container-form>
    <template #form>
      <b-row class="d-flex justify-content-between">
        <b-col cols="6">
          <div class="d-flex flex-column align-items-center pr-1">
            <h3>Contas a pagar</h3>
            <span>Anexe o arquivo com dados dos últimos 12 meses</span>
            <cc-dropzone v-model="nfeAPagar.file" ref="recebimento"/>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="d-flex flex-column align-items-center pr-1">
            <h3>Contas a receber</h3>
            <span>Anexe o arquivo com dados dos últimos 12 meses</span>
            <cc-dropzone v-model="nfeAReceber.file" ref="emissao"/>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-start align-items-center mt-3">
          <b-button variant="outline-primary" class="mr-1" @click="showCancelSwal">
            Cancelar
          </b-button>
          <b-button variant="primary"  class="mr-1" :disabled="!hasAllNFE" @click="sendNFE">
            {{ positiveActionName }}
          </b-button>
          <span>0,03% de desconto na taxa após a validação desta etapa</span>
        </b-col>
      </b-row>
    </template>
  </container-form>
</template>

<script>
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'
import CcDropzone from '@/@core/components/cc-dropzone/CcDropzone.vue';
import { BRow, BCol, BButton } from 'bootstrap-vue'


export default {
  name: 'NfeForm',
  components: {
    ContainerForm,
    CcDropzone,
    BRow,
    BCol,
    BButton
  },
  props: {
    loadedNfes: {
      type: Array,
      default: () => ({})
    }
  },
  data () {
    return {
      nfeAReceber: {
        file: null,
        tipo: 'emissao',
        id: null
      },
      nfeAPagar: {
        file: null,
        tipo: 'recebimento',
        id: null
      },
    }
  },
  async mounted() {
    if (this.loadedNfes.length) await this.loadNfe()
  },
  methods: {
    async sendNFE () {
      try {
        this.$swal.showLoading()
        await this.$store.dispatch('dashboard/sendNFE', {nfeAReceber: this.nfeAReceber, nfeAPagar: this.nfeAPagar})
        await this.$swal.fire({
          icon: 'success',
          title: 'NFEs enviadas com sucesso!',
          showConfirmButton: false,
          timer: 1500
        })
        this.$emit('nfeSubmited')
      } catch (error) {
        let errorMessage = 'Erro ao tentar enviar as NFEs, verifique os erros! <br/>'

        error.forEach(error => {
          const tipo = error.reason.config.data.getAll('tipo')
          errorMessage += `<strong>NFE de ${tipo}:</strong> <br/>`
          error.reason.response.data.error.forEach((error, index) => {
            errorMessage += `<strong>${index+1}</strong> - ${error} <br/>`
          })
          this.clearNfe(tipo)
        })

        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: errorMessage,
        })
      }
    },
    clearNfe (tipo) {
      this.$refs[tipo].remove(0)
    },
    showCancelSwal () {
      this.$swal.fire({
        text: 'Essa etapa é necessária para dar continuidade na contratação. Você tem certeza que deseja cancelar esta operação?',
        reverseButtons: true,
        confirmButtonColor: '#093272',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.dismiss === 'cancel') this.$router.push({ name: 'dashboard' })
      })
    },
    async loadNfe () {
      this.loadedNfes.filter(loadedNfe => {
        if (loadedNfe.tipo === "recebimento") this.nfeAPagar.id = loadedNfe.id
        if (loadedNfe.tipo === "emissao") this.nfeAReceber.id = loadedNfe.id

        this.$nextTick(() => this.$refs[`${loadedNfe.tipo}`].addFile(new File([""], loadedNfe.fileName)))
      })
    }
  },
  computed: {
    hasAllNFE() {
      return this.nfeAPagar.file && this.nfeAReceber.file
    },
    nfeWasLoadedByApi() {
      return this.nfeAReceber.id > 0 && this.nfeAPagar.id > 0
    },
    positiveActionName () {
      return this.nfeWasLoadedByApi ? 'Continuar' : 'Enviar'
    }
  },
  watch: {
    'nfeAPagar.file': {
      handler: function (val) {
        if (!val) this.nfeAPagar.id = null
      },
      deep: true
    },
    'nfeAReceber.file': {
      handler: function (val) {
        if (!val) this.nfeAReceber.id = null
      },
      deep: true
    },
  }
}
</script>