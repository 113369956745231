<template>
  <container-form>
    <template #form v-if="speds">
      <b-row class="d-flex justify-content-between">
        <b-col cols="4" v-for="(sped, index) in speds" :key="index">
          <div class="d-flex flex-column align-items-center pr-1">
            <h3>SPED de {{ sped.ano_referencia }}</h3>
            <cc-dropzone v-model="speds[index].file" :ref="`sped${sped.ano_referencia}`" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-start align-items-center mt-3">
          <b-button variant="outline-primary" class="mr-1" @click="showCancelSwal">
            Cancelar
          </b-button>
          <b-button variant="primary"  class="mr-1" :disabled="!hasAllSpeds" @click="sendSpeds">
            {{ positiveActionName }}
          </b-button>
          <span>0,03% de desconto na taxa após a validação desta etapa</span>
        </b-col>
      </b-row>
    </template>
  </container-form>
</template>

<script>
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'
import CcDropzone from '@/@core/components/cc-dropzone/CcDropzone.vue';
import { BRow, BCol, BButton } from 'bootstrap-vue'


export default {
  name: 'SpedForm',
  props: {
    loadedSpeds: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ContainerForm,
    CcDropzone,
    BRow,
    BCol,
    BButton
  },
  data () {
    return {
      speds: [],
    }
  },
  async mounted() {
    await this.$store.dispatch('dashboard/getSpedReferenceYears')
    this.speds = this.$store.state.dashboard.spedFiscal.referenceYears.map(year => ({
      ano_referencia: year,
      file: null
    }))
    if (this.loadedSpeds.length) await this.loadSpeds()
  },
  methods: {
    async sendSpeds () {
      if (this.spedWasLoadedByApi) return this.$emit('spedSubmited')

      try {
        this.$swal.showLoading()
        await this.$store.dispatch('dashboard/sendSpeds', this.speds)
        this.$swal.fire({
          icon: 'success',
          title: 'SPEDs enviados com sucesso!',
          showConfirmButton: false,
          timer: 1500
        })
        this.$emit('spedSubmited')
      } catch (error) {
        let errorMessage = 'Erro ao tentar enviar os SPEDs, verifique os erros! <br/>'

        error.forEach(error => {
          const ano_referencia = error.reason.config.data.getAll('ano_referencia')
          errorMessage += `<strong>SPED ${ano_referencia}:</strong> <br/>`
          error.reason.response.data.errors.forEach((error, index) => {
            errorMessage += `<strong>${index+1}</strong> - ${error} <br/>`
          })
          this.clearSped(ano_referencia)
        })

        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: errorMessage,
        })
      }
    },
    clearSped (ano_referencia) {
      this.$nextTick(() => {
        this.$refs[`sped${ano_referencia}`][0].remove(0)
      })
    },
    showCancelSwal () {
      this.$swal.fire({
        text: 'Essa etapa é necessária para dar continuidade na contratação. Você tem certeza que deseja cancelar esta operação?',
        reverseButtons: true,
        confirmButtonColor: '#093272',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.dismiss === 'cancel') this.$router.push({ name: 'dashboard' })
      })
    },
    async loadSpeds () {
      this.loadedSpeds.filter(loadedSped => {
        this.speds.filter(sped => {
          if (sped.ano_referencia === loadedSped.ano_referencia) sped.id = loadedSped.id

          this.$nextTick(() => {
            this.$refs[`sped${loadedSped.ano_referencia}`][0].addFile(new File([""], loadedSped.fileName))
          })
        })
      })
    }
  },
  computed: {
    hasAllSpeds() {
      return this.speds.every(sped => sped.id instanceof Number || sped.file instanceof File)
    },
    spedWasLoadedByApi() {
      return this.speds.every(sped => sped.id > 0 && sped.file instanceof File)
    },
    positiveActionName () {
      return this.spedWasLoadedByApi ? 'Continuar' : 'Enviar'
    }
  },
  watch: {
    speds: {
      handler: function (newVal, oldVal) {
        if (newVal.length) newVal.filter(sped => {
          if (sped.file === "") sped.id = null
        })
      },
      deep: true
    }
  }
}
</script>